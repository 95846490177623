import ColumnEligibilityStatus from "../components/pages/AllTransactions/components/ColumnGrid/ColumnEligibilityStatus";
import ColumnActions from "../components/pages/AllTransactions/components/ColumnGrid/ColumnActions";
import ColumnReCall from "../components/pages/AllTransactions/components/ColumnGrid/ColumnReCall";
import React from "react";
import ActionButton from "../components/ActionButton";
import ColumnAudited from "../components/pages/AllTransactions/components/ColumnGrid/ColumnAudited";

export const transactionActionsColumnsByModule = (
  history,
  audit,
  handleVerify,
  tableReference,
  module_key,
  openModalVerification,
  handleIssueButtonClick,
  practiceId,
  issuesReport = false,
  permissions,
) => {
  const actionButton = {
    CC: {
      title: "Actions",
      sorting: false,
      align: "center",
      render: (rowData) => (
        <div
          className="d-flex justify-content-center align-items-center"
          onClick={() => {
            openModalVerification(rowData);
          }}
        >
          <ActionButton />
        </div>
      ),
    },
    AT: {
      title: "Actions",
      sorting: false,
      align: "center",
      render: (rowData) => (
        <ColumnActions
          rowData={rowData}
          history={history}
          audit={audit}
          handleVerify={handleVerify}
          tableReference={tableReference}
          permissions={permissions}
        />
      ),
    },
  };

  const actionsColumns = [
    {
      sorting: false,
      align: "center",
      title: "Eligibility Status",
      field: "eligibility",
      render: ({ iv_status = "" }) => {
        return <ColumnEligibilityStatus iv_status={iv_status} />;
      },
    },
    {
      sorting: false,
      title: "Audited",
      align: "center",
      render: ({
        audited,
        _id,
        practiceId: _practiceId,
        practiceName: _practiceName,
      }) => {
        return (
          <ColumnAudited
            audited={audited}
            _id={_id}
            practiceId={practiceId}
            issuesReport={issuesReport}
            handleIssueButtonClick={handleIssueButtonClick}
          />
        );
      },
    },
  ];

  return [
    ...actionsColumns,
    actionButton[module_key],
    /*{
      title: "Recall",
      render: ({ iv_process_result, iv_status }) => (
        <ColumnReCall
          ivProcessResult={iv_process_result}
          ivStatus={iv_status}
        />
      ),
      permission: "recallNotification",
    },*/
  ];
};
